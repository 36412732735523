/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.33em;
    margin-bottom: 9em;
    min-height: calc(49vh - 80px);
    font-size: 18px;
}
/*------Контейнер для блока с количеством голосующих------------------------------------------------------------------*/
.main-count-voters {
    display: flex;
    flex-direction: column;
    width: 24em;
    height: 18em;
    background: #FFFFFF;
    padding: 1.77em 1.77em;
    box-shadow: -3px 0 8px rgba(54, 58, 72, 0.1);
    border-radius: 1.33em;
}
.main-count-voters__title {
    font-size: 1.22em;
    line-height: 110%;
    color: rgba(54, 59, 77, 1);
    font-weight: 500;
    margin-bottom: 0.44em;
}
.main-count-voters__all-count {
    font-size: 0.88em;
    color: rgba(54, 59, 77, 0.6);
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 1.77em;
}
.main-count-voters__count {
    font-size: 4.44em;
    font-weight: 600;
    color: rgba(0, 132, 254, 0.8);
}
/*------Контейнер для блока с переходом на страницу с голосованиями---------------------------------------------------*/
.main-redirect-votes-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.22em;
    width: 38em;
    height: 18em;
    background: #FFFFFF;
    padding: 1.77em 1.77em;
    box-shadow: -3px 0 8px rgba(54, 58, 72, 0.1);
    border-radius: 1.33em;
}
.main-redirect-votes-page-link-vote {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.main-redirect-votes-page-link-vote__text {
    font-size: 1.22em;
    line-height: 136%;
    font-weight: 500;
    color: rgba(54, 59, 77, 1);
    margin-bottom: 2.22em;
}
.main-redirect-votes-page-link-vote__link-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    font-size: 0.88em;
    background:#0084fe;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 0.22em;
}
.main-redirect-votes-page-link-vote__link-btn:hover {
    background: #FFFFFF;
    border: 1px solid#0084fe;
    color:#0084fe;
}
.main-redirect-votes-page-link-vote__image {
    width: 17.38em;
    height: 14.66em;
    top: 0;
    right: 0;
    object-fit: cover;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .main {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1120px) {
    .main {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 830px) {
    .main {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 600px) {
    .main {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .main {
        flex-direction: column;
        gap: 16px;
    }
    .main-count-voters {
        width: 100%;
    }
    .main-redirect-votes-page {
        width: 100%;
    }
    .main-count-voters__title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .main-count-voters__all-count {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .main-count-voters__count {
        font-size: 30px;
    }
    .main-redirect-votes-page-link-vote__text {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .main-redirect-votes-page-link-vote__link-btn {
        height: 30px;
        font-size: 12px;
    }
}