/*------Контейнер для страницы авторизации-------------------------------------------------------------------------*/
.wrapper-auth {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1280px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }

  @media (max-width: 992px) {
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    transform: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.container-auth {
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: content-box;
}
.main-block {
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
    min-height: 100%;
    height: 100vh;
  }
}
a {
  text-decoration: underline;
  cursor: pointer;
}
/*------Блок авторизации--------------------------------------------------------------------------------------------*/
.main-block__auth {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #ffff;
  padding: 74px 56px 74px 56px;
  width: 768px;
  font-size: 18px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
  color: rgba(54, 59, 77, 1);

  @media (max-width: 1280px) {
    font-size: 14px;
    padding: 32px;
  }

  @media (max-width: 992px) {
    font-size: 12px;
    padding: 15px 40px;
    gap: 24px;
  }

  @media (max-width: 767px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 24px 16px 24px 16px;
    font-size: 16px;
    width: 100%;
    border-radius: 16px 16px 0 0;
  }
}
.auth__title {
  display: inline-flex;
  align-items: center;

  color: rgba(54, 59, 77, 1);
}
.auth__title h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.auth__title span {
  padding-right: 8px;
  font-size: 16px;
  cursor: pointer;
}
.auth__title span:last-child {
  font-size: 16px;
  color: #0084fe;
  text-decoration: underline;
}
.auth__form {
  display: flex;
  flex-direction: column;
}

.form__login {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
}
.form__login input {
  border-radius: 8px;

  height: 56px;
  padding: 5px 24px;
  font-size: 18px;
  outline: none;
  border: 1px solid rgba(54, 59, 77, 0.3);
}
.form__pass {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.form__pass-container {
  position: relative;
}

.form__pass input {
  width: 100%;
  border-radius: 8px;
  height: 56px;
  padding: 5px 50px 5px 24px;
  font-size: 18px;
  outline: none;
  border: 1px solid rgba(54, 59, 77, 0.3);
}
.form__pass-show-pass-icon {
  position: absolute;

  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
}
.form__checkbox {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 30px;
}
.form__checkbox a {
  color: #0084fe;
}
.form__button {
  display: inline-flex;
  gap: 32px;
  align-items: center;
  position: relative;
}
.form__button__link-gosuslugi {
  color: #0084fe;
}
.form__button-input-btn {
  background-color: #0084fe;
  width: 185px;
  height: 56px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;
}
.form__button-input-btn:hover {
  background-color: #0070d7;
}

.form__button-input-btn:active {
  background-color: #004c99;
}

.form__button-input-btn.active {
  background-color: #004c99;
  border: none;
  cursor: default;
}
.form__error-phone-code {
  color: #ff4970;
  font-size: 12px;
}
/*------Блок для входа по телефону------------------------------------------------------------------------------------*/
.auth__form-phone-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.auth__form-phone-fields {
  width: 100%;
}
.auth__form-phone-sms-fields {
  width: 100%;
}
.auth__form-phone-sms-fields.hidden {
  display: none;
}
.auth__form-phone-sms-code-btn {
  font-size: 18px;
  padding: 16px 16px;
  background: none;
  border-radius: 4px;
  border: 1px solid #0084fe;
  color: #0084fe;
  cursor: pointer;
}
.auth__form-phone-sms-code-btn.hidden {
  display: none;
}
.auth-form__select-email-phone-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.auth__form-phone-sms-code-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  height: 56px;
}
.auth__form-phone-sms-code-block input {
  padding: 5px 24px;
  max-width: 155px;
}
.auth__form-phone-sms-message {
  font-size: 14px;
}
.auth__form-phone-sms-message.hidden {
  display: none;
}
.form__pass-active-fields-phone {
  display: none;
}
.auth__form-disactive-sms-code {
  cursor: pointer;
  text-decoration: underline;
  color: #0084fe;
  font-size: 14px;
}
.auth-form__select-email {
  font-size: 18px;
  cursor: pointer;
}
.auth-form__select-phone {
  font-size: 18px;
  cursor: pointer;
}
.auth-form__select-email:hover {
  color: #0084fe;
  text-decoration: underline dashed;
}
.auth-form__select-phone:hover {
  color: #0084fe;
  text-decoration: underline dashed;
}
.auth-form__select-email.active {
  color: #0084fe;
  text-decoration: underline dashed;
}
.auth-form__select-phone.active {
  color: #0084fe;
  text-decoration: underline dashed;
}
/*------Блок регистрации-----------------------------------------------------------------------------------------------*/
.main-block__reg {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #373c4e;
  padding: 74px 30px;
  width: 336px;
  border-radius: 0 8px 8px 0;
  background-image: url("../../img/reg-bg-desk.svg");
  background-position: bottom;
  background-repeat: no-repeat;

  @media (max-width: 1280px) {
    padding: 32px;
  }

  @media (max-width: 992px) {
    background-position: bottom;
    padding: 20px;
    padding-top: 40px;
  }

  @media (max-width: 767px) {
    background-image: url("../../img/reg-bg.svg");
    background-position: top right;
    width: 100%;
    height: 100%;

    border-radius: 0;
  }
}
.reg__logo {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  gap: 23px;

  @media (max-width: 1280px) {
    gap: 0;
  }

  @media (max-width: 992px) {
    padding-bottom: 320px;
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
    width: 100%;
  }
}

.reg__logo-second {
  width: 112px;
  height: 14px;
  align-self: flex-end;
}
.reg__title {
  margin-top: auto;
  text-align: right;

  padding-bottom: 32px;
  color: #ffffff;
  font-size: 24px;
  // font-weight: 700;
  line-height: 32px;
}
.reg__button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  // margin-top: auto;
}
.reg__button span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.reg__button button,
.reg__button a {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 213px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #0084fe;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  z-index: 66;
}

/*------Сообщение об ошибки--------------------------------------------------------------------------------------------*/
.form__error {
  margin-top: -16px;
  font-weight: 400;
  line-height: 143%;
  color: #ff4970;
}
/*------Блок с ссылкой для мобильная версии на страницу регистрации--------------------------------------------------*/
.main-block__mobile-link-reg {
  display: none;
}
/*------Стилизация картинок-фигур в блоке с кнопкой зарегистрироваться--------------------------------------------------*/
.auth-image_1 {
  position: absolute;
  width: 342px;
  height: 432px;
  top: 150px;
  left: 112px;
  object-fit: cover;
}

.auth-image_3 {
  position: absolute;
  top: 22px;
  left: 78px;
  object-fit: cover;
}
.auth-image_3 img {
  width: 600px;
  height: 800px;
}

.auth-image_4 {
  position: absolute;
  width: 117px;
  height: 228px;
  top: 458px;
  right: 224px;
  object-fit: cover;
}
.auth-image_5 {
  position: absolute;
  width: 228px;
  height: 228px;
  top: 560px;
  right: 110px;
  object-fit: cover;
}
.auth-image-mobile {
  display: none;
}
/*------Стилизация ссылок забыть пароль и войти как секретарь--------------------------------------------------------------------------------------------------*/
.link-forget-pass {
  cursor: pointer;
  text-decoration: underline;
}
.checkbox__link-forget-pass-login-secretary {
  display: inline-flex;
  justify-content: space-between;
  /*margin: 0 5px;*/
}
/*------Чек бокс--------------------------------------------------------------------------------------------------*/
.auth-form__checkbox-container {
  display: flex;
  margin: 0 auto 0 0;
  cursor: pointer;
  gap: 14px;
}
.checkbox_container {
  position: relative;
  width: 20px;
  height: 20px;
}
.checkbox_container input {
  position: absolute;
  opacity: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-color: #cacccf;
  border-style: solid;
  border-width: 1px;
}
.checkbox_container:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox_container input:checked ~ .checkmark {
  background-color: #0084fe;
  border-color: #0084fe;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}
.checkbox_container .checkmark:after {
  left: 4px;
  top: 3px;
  width: 8px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  /*-webkit-transform: rotate(45deg);*/
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.auth__remember-me-checklabel {
  color: #363b4d;
  opacity: 0.9;
  font-size: 18px;
  margin: auto 0 auto 0;
}
.reg__logo-main {
  max-width: 130px;
  margin-left: auto;
}
/*------Адаптивная версия(1108px)--------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
  .container-auth {
    max-width: 800px;
  }

  .auth__title {
    padding-bottom: 20px;
  }
  .auth__title h3 {
    font-size: 22px;
  }
  .form__select select {
    font-size: 14px;
  }
  .form__select,
  .form__login,
  .form__pass input {
    max-width: 450px;
  }
  .form__button-input-btn {
    height: 40px;
  }
  .checkbox_container {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .auth__remember-me-checklabel {
    font-size: 14px;
  }
  .reg__title {
    position: absolute;
    text-align: right;
    top: 300px;
    right: 24px;
    padding-bottom: 32px;
    color: #ffffff;
    font-size: 18px;
    // font-weight: 700;
    line-height: 32px;
  }

  /*.reg__logo img {*/
  /*    width: 180px;*/
  /*}*/

  .reg__logo-second {
    width: 100px;
    height: 14px;
    align-self: flex-end;
  }
  .reg__button span {
    font-size: 14px;
  }

  .auth__form-phone-sms-code-btn {
    font-size: 16px;
    padding: 16px 16px;
  }

  .auth-form__select-email {
    font-size: 14px;
  }
  .auth-form__select-phone {
    font-size: 14px;
  }
  /*.reg-form__select-email-phone {*/
  /*    font-size: 18px;*/
  /*}*/
}
/*------Адаптивная версия(800px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
  .container-auth {
    max-width: 700px;
  }

  .auth__title {
    padding-bottom: 10px;
  }
  .auth__title h3 {
    font-size: 20px;
  }

  .form__select select {
    font-size: 12px;
  }
  .form__select input,
  .form__login input,
  .form__pass input {
    width: 100%;
  }
  .form__button-input-btn {
    height: 40px;
  }
  .form__button span {
    text-align: left;
  }

  .reg__title {
    position: absolute;
    text-align: right;
    top: 300px;
    right: 24px;
    padding-bottom: 32px;
    color: #ffffff;
    font-size: 18px;
    // font-weight: 700;
    line-height: 32px;
  }
  /*.reg__logo img {*/
  /*    width: 160px;*/
  /*}*/
  .reg__logo-main {
    width: 160px;
    height: 28px;
  }
  .reg__logo-second {
    width: 80px;
    height: 14px;
    align-self: flex-end;
  }
  .reg__button span {
    font-size: 12px;
  }
  .form__select,
  .form__login,
  .form__pass {
    padding-bottom: 32px;
  }

  .form__pass-show-pass-icon {
    right: 15px;
  }
  .auth__form-phone-sms-code-block input {
    padding: 5px 10px 5px 10px;
    max-width: 120px;
    font-size: 14px;
  }

  .auth-form__select-email-phone-container {
    height: 12px;
  }
  .auth-form__select-email {
    font-size: 12px;
  }
  .auth-form__select-phone {
    font-size: 12px;
  }
}
/*------Адаптивная версия(600px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  .container-auth {
    position: relative;
    width: 100%;
    padding: 0;
    max-width: 440px;
  }

  .auth__title {
    padding-bottom: 24px;
    // max-width: 343px; ??
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
  }
  .auth__title h3 {
    font-size: 26px;
    font-weight: 700;
  }
  .auth__title span {
    font-size: 14px;
  }
  .auth__title span:last-child {
    font-size: 14px;
  }
  .form__button {
    width: 100%;
    padding-bottom: 16px;
    gap: 12px;
  }
  .form__button-input-btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
  }

  .form__button span {
    border: 1px solid #0084fe;
    width: 199px;
    height: 48px;
    border-radius: 4px;
    text-align: center;
    padding: 14px 22px 12px 22px;
    cursor: pointer;
  }
  .form__button__link-gosuslugi {
    color: #0084fe;
    text-decoration: none;
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    padding: 12px 26px 12px 26px;
  }
  .form__checkbox {
    padding-bottom: 24px;
    gap: 25px;
    font-size: 14px;
  }
  .reg__title {
    display: none;
  }

  .reg__logo-main {
    width: 190px;
    height: 33px;
    align-self: flex-start;
  }
  .reg__logo-second {
    width: 100px;
    height: 18px;
    align-self: flex-end;
  }
  .reg__button {
    display: none;
  }
  .reg__button span {
    display: none;
  }
  .form__login,
  .form__pass {
    padding-bottom: 20px;
    max-width: 100%;
  }
  .form__login input,
  .form__pass input {
    padding: 10px 35px 10px 10px;
    width: 100%;
    height: 48px;
  }
  .checkbox_container {
    padding-top: 1px;
    font-size: 16px;
    padding-left: 28px;
  }
  .auth__remember-me-checklabel {
    font-size: 16px;
    /*padding-left: 24px;*/
  }
  .checkmark {
    height: 18px;
    width: 18px;
  }
  .auth-image_1,
  .auth-image_2,
  .auth-image_3,
  .auth-image_4,
  .auth-image_5,
  .auth-image_6 {
    display: none;
  }
  .main-block__mobile-link-reg {
    display: flex;
    width: 100%;
    text-align: center;
    gap: 5px;
    // position: absolute;
    font-size: 16px;
    // left: 28px;
    // top: 635px;
    color: rgba(54, 59, 77, 1);
  }

  .main-block__mobile-link-reg span:last-child {
    color: #0084fe;
    text-decoration: underline;
    cursor: pointer;
  }
  .auth-image-mobile {
    display: inherit;
    position: absolute;
    width: 300px;
    height: 250px;
    top: 0;
    left: 120px;
    object-fit: cover;
  }
  .form__error {
    font-size: 12px;
    font-weight: 400;
    line-height: 143%;
    color: #ff4970;
  }

  .checkbox__link-forget-pass-login-secretary {
    margin-right: 10px;
  }
  .auth__form-phone-sms-code-btn {
    font-size: 16px;
    padding: 12px 12px;
  }
  .auth-form__select-email-phone-container {
    height: 16px;
  }
  .auth__form-phone-sms-code-block {
    height: 48px;
  }

  .auth__form-phone-sms-code-block input {
    padding: 5px 10px 5px 10px;
    max-width: 120px;
    font-size: 16px;
  }
  .auth__form-phone-sms-message {
    font-size: 12px;
    margin-right: 15px;
  }
  .auth-form__select-email {
    font-size: 16px;
  }
  .auth-form__select-phone {
    font-size: 16px;
  }
}

.input-error {
  border: 1px solid red;
}

.form__views {
  margin-bottom: 16px;
}
